@import "../variables";

.tag {
  font-weight: bolder;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: min-content;
  border: 1px solid;
  cursor: default;

  &:hover {
    text-decoration: none !important;
  }

  &.tag-active {
    border-color: transparent;
    background-color: $tag-active-bg;
    color: $tag-active-color;
  }

  &.tag-draft {
    border-color: transparent;
    background-color: $tag-draft-bg;
    color: $tag-draft-color;
  }

  &.tag-info {
    border-color: $tag-info-border-color;
    background-color: $tag-info-bg;
    color: $tag-info-color;
  }

  &.tag-archived {
    border-color: transparent;
    background-color: $light-blue-200;
    color: $light-blue;
  }
}