html,body{
    background-color: #FAFAFA;
}

.h3 {
    font-size: 1.5em;
    font-weight: 800;
    margin-bottom: 0;
}

h3 {
    font-size: $h3;
}

h4 {
    font-size: $h4;
}

h5 {
    font-size: $h5;
}

h6 {
    font-size: $h6;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
}

.text-small{
    font-size:0.75rem!important;
}

p{
    font-size: $paragraph;
}

.countdown-text {
    &__large {
        font-size: 64px;
        font-weight: 800;
        line-height: 55px;
    }
    &__medium {
        font-size: 64px;
        font-weight: 800;
        line-height: 55px;
    }
    &__small {
        font-size: 36px;
        font-weight: 800;
    }
}




.large-para{
    font-size:1.25rem;
}

.text-bold{
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
}

.text-red {
    color: $red;
}

.text-link {
    cursor: pointer;
    color: $brand-primary !important;
}

.text-blue-steel  {
    color: $light-steel-blue !important;
}

.text-oxford-blue  {
    color: $oxford-blue !important;
}

@include media-breakpoint-up(lg) {
    .h1 {
        &--large {
            font-size: 4em;
        }
    }

}
