@import "../variables";
@import "../flexbox/mixins";

.panel:not([data-test="basic-information"]) {
	width: 100%;
	.panel-heading {
		h1, h2, h3 {
			color: $panel-heading-color;
			margin: 0;
		}
		.icon {
			font-size: 1.5em;
		}
		font-weight: 500;
		font-size: 1em;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		height: $panel-heading-height;
		flex: 1 1 0%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: $input-padding-x;
		padding-right: $input-padding-x;
		background-color: $panel-heading-bg;
		text-transform: capitalize;
		color: $panel-heading-color;

	}

	.panel-content {
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
		transform: none;
		background-color: $panel-bg;
		padding: $input-padding-y $input-padding-x;

	}

	input[type="text"] {
		opacity: 0.5;
		&:focus {
			opacity: 1;
		}
		outline: none;
		border: none;
		border-radius: 2px;
		background: rgba(0, 0, 0, .2);
		padding: 2px 10px 2px 30px;
		font-weight: normal;
		color: $panel-heading-color;
	}

	&.panel-alt {
		>.panel-heading {
			background-color: $panel-alt-heading-bg;
			color: $panel-alt-heading-color;

			h1, h2, h3 {
				color: $panel-alt-heading-color;
				margin: 0;
			}
		}
	}

	.panel-icon {
		display: inline-block;
		@include flexbox();
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 16px;
		justify-content: center;
		background-color: $panel-icon-background-color;
		border: 1px solid $panel-icon-border-color;
		margin-right: $input-padding-x/2;
		.icon {
			display: inline-block;
			text-align: center;
			font-size: 18px;
			line-height: 18px;
			color: $panel-icon-color;
		}
	}

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	box-shadow: rgba(75, 75, 75, 0.11) 0px 1px 6px, rgba(75, 75, 75, 0.11) 0px 1px 4px;
	border-radius: 4px;

	&.no-pad {

		.panel-content {
			padding: 0;

		}
	}
}
