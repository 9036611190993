.select {
    position: relative;
    width: 100%;
    select {
        width: 100%;
        font-size: 16px;
        text-indent: 5px;
        outline: none;
        height: 38px;
        background: #F4F6FC;
        border: 1px solid $light-steel-blue;
        border-radius: $base-border-radius;
        color: $oxford-blue;
        padding-left: 5px;
        padding-right: 5px;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        cursor:pointer;
    }
    &__icon {
        pointer-events: none;
        position: absolute;
        top: 9px;
        right: 9px;
    }
    &__text {
        display: block;
        color: $oxford-blue;
        letter-spacing: 0;
        margin-bottom: 0;
    }
    &__white {
        background-color: white !important;
    }
}
