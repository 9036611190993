.alert {
    border: none;
    background-color: transparent;
    padding: 0;
    &-danger {
        color: $red;
    }
    .icon {
        padding-right: .5em;
    }
}
.text-danger {
    p {
        color: $red;
    }
}
