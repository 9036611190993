.popover-bt{
    border-top:8px solid #2cd6ca;
    border-radius: 8px;
    overflow:visible;

    &:before{
        content:"";
        position: absolute;
        top:-18px;
        right:20px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 16px solid #2cd6ca;
    }

    &.aside-select{
        padding-bottom:0;
    }

    a{
        color:#000;
        font-weight:100;
    }

    .list-item{
        padding:5px 5px 5px 10px;
    }
}