.nav-pills {
    cursor: pointer;
    .nav-link {
        color: $oxford-blue;
        opacity: 0.7;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 800;
        &.active {
            background-color: $brand-primary;
            box-shadow: 0 6px 13px 0 rgba(247,164,0,0.20);
            border-radius: $base-border-radius;
            color: white;
            opacity: 1;
        }
    }
    &--large {
        cursor: pointer;
        .nav-link {
            color: $oxford-blue;
            opacity: 0.7;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 800;
            font-size: 20px;
            &.active {
                background-color: white;
                border-radius: $base-border-radius;
                color: $oxford-blue;
                opacity: 1;
                box-shadow: 0 6px 13px 0 rgba(52,48,144,0.10);
            }
        }
    }
}
