.btn {
    border-radius: $base-border-radius;
    font-weight: 600;
    &__medium {
        font-size: 13px;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
    &__small {
        font-size: 13px;
        height: 28px;
        .btn__icon {
            width: 24px;
            height: 24px;
        }
    }
    &.flex-row-reverse {
        .btn__icon {
            margin-right: 10px;
            margin-left: 0px;
        }
    }
    &__icon {
        border-radius: 6px;
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }
    &:focus {
        box-shadow: none;
    }
    &-add {
        margin: 0 1em;
        z-index: 1;
        .question-tooltip {
            font-size: 25px;
        }
        &--bg-white {
            background-color: white;
        }
        &--bg-ghost {
            background-color: $ghost-white;
        }
        &--bg-group {
            background-color: #EBEEF7;
        }
        .btn {
            width: 38px;
            height: 38px;
            box-shadow: 0 6px 13px 0 rgba(247,164,0,0.20);
         
        }
        &__text {
            font-size: 11px;
            text-transform: uppercase;
            color: $oxford-blue;
        }
    }
    &__round {
        border-radius: 50%;
    }
    &-white {
        background-color: white;
        border: 1px solid $light-steel-blue;
        box-shadow: 0 6px 13px 0 rgba(52,48,144,0.10);
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        .icon {
            font-size: 11px;
            color: $oxford-blue;
            background-color: transparent;
            box-shadow: none !important;
        }
    }
    &__organisation {
        text-align: left;
        width: 100%;
    }
}

button:disabled {
    cursor: not-allowed;
}

.btn-text {
    color: $brand-primary;
    background-color: transparent;
    padding: 0;
    &:hover {
        color: lighten($brand-primary, 5);
    }
    &--danger {
        color: $red;
        &:hover {
            color: darken($red, 5);
        }
    }
    &--navy {
        color: $oxford-blue;
        .btn__icon {
            color: $brand-primary;
        }
    }
    &--small {
        font-size: 13px;
    }
}

.btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-primary;
    border-color: $brand-primary;
    &:disabled, &:disabled:hover {
        border-color: $brand-primary;
        background-color: $brand-primary;
    }
    &:hover {
        background-color: darken($brand-primary, 5);
        border-color: $brand-primary;
    }
    .btn__icon {
        background-color: $maximum-yellow;
    }
    &:focus {
        background-color: darken($brand-primary, 5)!important;
        box-shadow: 0 6px 13px 0 rgba(247,164,0,0.20)!important;
        border-color: $brand-primary !important;
    }
}

.btn-danger {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red;
    border-color: $red;
    &:disabled, &:disabled:hover {
        border-color: $red;
        background-color: $red;
    }
    &:hover {
        background-color: darken($red, 5);
        border-color: $red;
        color: white;
    }
    .btn__icon {
        background-color: lighten($red, 10);
    }
    &:focus {
        background-color: darken($red, 5)!important;
        box-shadow: 0 6px 13px 0 rgba(247,164,0,0.20)!important;
        border-color: $red !important;
        color: white;
    }
}

.btn-danger-outline {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-color: $red;
    color: $red;
    &:disabled, &:disabled:hover {
        border-color: $red;
        background-color: $red;
    }
    &:hover {
        background-color: darken($red, 5);
        border-color: $red;
        color: white;
    }
    .btn__icon {
        background-color: $red;
        color: white;
    }
    &:focus {
        background-color: darken($red, 5)!important;
        box-shadow: 0 6px 13px 0 rgba(247,164,0,0.20)!important;
        border-color: $red !important;
        color: white;
    }
}

.btn-secondary {
    background-color: transparent;
    border-color: $brand-primary;
    color: $brand-primary;
    &:hover {
        background-color: transparent;
        color: $brand-primary;
        border-color: $brand-primary;
    }
    &:focus {
        background-color: transparent;
        color: $brand-primary;
        border-color: $brand-primary;
    }
    &:active {
        background-color: transparent;
        color: $brand-primary;
        border-color: $brand-primary;
    }
    &:disabled, &:disabled:hover {
        border-color: $brand-primary;
        background-color: transparent;
        color: $brand-primary;
    }
    &__icon {
        background-color: $brand-primary;
        color: white;
    }
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: $brand-primary;
    border-color: $brand-primary;
}

.btn-outline-primary {
    background-color: white;
    border-color: $brand-primary;
    color: $brand-primary;
    &:disabled, &:disabled:hover {
        border-color: $brand-primary;
        background-color: white;
    }
    &:hover {
        background-color: darken($brand-primary, 5);
        border-color: darken($brand-primary, 5);
    }
}

.btn-white {
    color: white;
    .icon {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 1px -1px rgba(0, 0, 0, 0.03), 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    }
}

.dropdown {
    &-menu {
        box-shadow: 0 6px 13px 0 rgba(52,48,144,0.10);
        border-radius: $base-border-radius;
        border: none;
        &__organisation {
            background-color: $saint-blue;
            width: 97% !important;
        }
    }
    &-item {
        padding: 0 1em;
    
        &:hover {
            background-color: transparent;
        }
        &:focus{
            background-color: transparent;
        }
    }
}


.btn-sm {
    .btn__icon {
        width: 20px;
        height: 20px;
        background-color: transparent;
    }
}
