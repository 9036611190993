.react-datepicker {
  &__header--time {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

/**
 * SA-746 data picker componenet doesn't show month switching arrows
 * picked the fix from this PR: https://github.com/Hacker0x01/react-datepicker/pull/3093
 * updating the datepicker component should also fix the issue
 */
.react-datepicker__navigation-icon {
  width: 0;
}