ol {
    padding-left: 0;
    li {
        color: #22354a;
        opacity: 0.85;
        font-size: $paragraph;
    }
}

.status {
    &__dot {
        width: 6px;
        height: 6px;
        border-radius: 50px;
        &.maximum-yellow {
            background-color: $maximum-yellow;
            &.pulse {
                animation: pulse-animation--yellow 2s infinite;
            }
        }
        &.red {
            background-color: $red;
            &.pulse {
                animation: pulse-animation--red 2s infinite;
            }
        }
        &.red-500 {
            background-color: $red-500;
            &.pulse {
                animation: pulse-animation--red-500 2s infinite;
            }
        }
        &.green {
            background-color: $aquamarine;
            box-shadow: 0px 0px 1px 1px $aquamarine;
            &.pulse {
                animation: pulse-animation--green 2s infinite;
            }
        }
        &.primary {
            background-color: $brand-primary;
            &.pulse {
                animation: pulse-animation--primary 2s infinite;
            }
        }
        &.primary-500 {
            background-color: $brand-primary-500;
            &.pulse {
                animation: pulse-animation--primary-500 2s infinite;
            }
        }
        &.saint-blue {
            background-color: $saint-blue;
            &.pulse {
                animation: pulse-animation--saint-blue 2s infinite;
            }
        }
        &.saint-blue-500 {
            background-color: $saint-blue-500;
            &.pulse {
                animation: pulse-animation--saint-blue-500 2s infinite;
            }
        }
        &.light-blue {
            background-color: $light-blue;
            &.pulse {
                animation: pulse-animation--light-blue 2s infinite;
            }
        }


    }
    &__text {
        &--medium {
            font-size: 15px;
            color: $oxford-blue;
        }
        &--small {
            font-size: 13px;
            color: $light-steel-blue;
        }
    }
    &-label {
        background-color: white;
        border-radius: $base-border-radius;
        font-size: 13px;
        padding: 0 8px;
        color: $oxford-blue-700;
        &--grey {
            background-color: $light-steel-blue-200;
        }
    }
}

.list-stat-item {
    &__heading {
        font-size: 20px;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: bold;
    }
    &__sub-heading {
        font-size: 13px;
    }
}

.list-dash {
    list-style-type: none;
    color: $light-steel-blue;
    padding-left: 20px;
    li {
        font-size: 13px;
        &:before {
            content: '\2014';
            position: absolute;
            margin-left: -25px;
        }
    }
}

.list-icon {
    &__container {
        width: 36px;
        height: 36px;
        border-radius: $base-border-radius;
        background-color: $light-steel-blue;
    }
    &__title {
        font-weight: bold;
        font-size: 15px;
        color: $oxford-blue-700;
    }
}

.list-icon-group {
    display: flex;
    flex-direction: column;

    &__item {
        padding: .5em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $light-steel-blue;
        border-radius: $base-border-radius;
        color: $light-steel-blue;
        cursor: pointer;
        &.active {
            border: 1px solid $brand-primary;
            color: $brand-primary;
        }
    }
}