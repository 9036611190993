.tooltip {
    box-shadow: 0 11px 15px -7px rgba(0,0,0,0.05), 0 9px 46px 8px rgba(0,0,0,0.03), 0 24px 38px 3px rgba(0,0,0,0.04);
    border-radius: $large-border-radius !important;
    opacity: 1;
    z-index: 1021 !important;
    &__text {
        font-weight: normal !important;
    }
    span {
        //font-weight: bold !important;
    }
    &__checkbox {
        position: relative;
        bottom: 10px;
    }
}

@include media-breakpoint-up(md) {
    .tooltip {
        //width: 25%;
    }
}
