.card {
  box-shadow: 0 6px 13px 0 rgba(52, 48, 144, 0.1);
  border-radius: 18px;
  padding: 2em;
  &__heading {
    &--h3 {
      h3 {
        font-size: $h4;
      }
    }
    &--h4 {
      h3 {
        font-size: $h4;
      }
    }
    &--h6 {
      h3 {
        font-size: $h6;
      }
    }
  }
  &--red {
    border: 1px solid $red;
    padding: 1em;
    border-radius: $base-border-radius;
  }
  &--white {
    background-color: white;
  }
  &--navy {
    background-color: navy;
  }
  &--saint-blue {
    background-color: $saint-blue;
  }
  &--grey {
    background: $ghost-white;
    border: 1px solid $light-steel-blue;
    box-shadow: none;
  }
  &--question-group {
    background: $light-steel-blue-100;
    border: 1px solid $light-steel-blue-500;
    box-shadow: none;
  }
}

.status-card {
  border-radius: $base-border-radius;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &--awaiting-approval {
    border-top: 4px solid $aquamarine;
  }
  &--open {
    border-top: 4px solid $brand-primary;
  }
  &--expired {
    border-top: 4px solid $red;
  }
  &--post-permit-checks {
    border-top: 4px solid $brand-primary-500;
  }
  &--awaiting-sign-off {
    border-top: 4px solid $saint-blue;
  }
  &:hover {
    transform: translateY(-5px);
  }
}

.status-card-container {
  color: inherit; /* blue colors for links too */
  &:hover {
    color: inherit;
    text-decoration: none; /* no underline */
  }
}

@include media-breakpoint-up(sm) {
  .permit-summary-card {
    min-height: 250px;
  }

  .permit-action-card {
    min-height: 250px;
  }

  .card {
    &--login {
      width: 380px;
      margin: auto;
    }
  }
}
