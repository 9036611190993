.breadcrumb {
    width: max-content;
    align-items: center;
    background-color: transparent;
    margin-bottom: 0;
    &-item {
        font-size: 15px;
        a {
            color: $brand-primary;
            font-weight: 800;
        }
        &.active {
            color: $oxford-blue;
        }
        & +.breadcrumb-item::before {
            color: $brand-primary;
            font-weight: bold;
            font-family: "Font Awesome 5 Pro";
            content: "";
        }
    }
}

