.modal {
  &-scrollable {
    max-height: 300px;
    overflow: scroll;
  }
  &-dialog {
    display: flex;
    justify-content: center;
  }
  &-content {
    border-radius: $base-border-radius;
    box-shadow: 0 6px 13px 0 rgba(52, 48, 144, 0.1);
    border: none;
  }
  &-header {
    color: $oxford-blue;
    border-bottom: 0px;
    padding-bottom: 0;
    .close {
      &:focus {
        outline: none;
      }
      span {
        background-color: $light-steel-blue;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        color: white;
        font-size: 22px;
      }
    }
  }
  &-title {
    font-size: 1.25em;
  }
  &-backdrop {
    background-color: $oxford-blue;
  }
  &-body {
    p {
      color: $oxford-blue;
    }
  }
  &-footer {
    border-top: 0;
  }
  &__pdf-viewer {
    width: 90%;
    max-width: 90%;
    height: 90%;

    .pdf-controller-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      .pdf-controller-box {
        background: rgba(0,0,0,0.7);
        border-radius: $border-radius;
        color: white;
        box-sizing: border-box;

        a:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal {
    display: flex !important;
    align-items: center !important;
  }
}
