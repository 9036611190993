.table {
    box-shadow: 0 6px 13px 0 rgba(52,48,144,0.10);
    background-color: white;
    border-radius: $base-border-radius;
    @include transition(opacity 500ms);
    margin-bottom: 0;
    &--loading {
        opacity: 0.5;
    }
    &__header {
        padding: .5em;
    }
    &__row {
        padding: .5em;
        &:hover {
            background-color: $lavender-300;
        }
        &.clickable {
            &.active {
                border-right: 3px solid $brand-primary;
                background-color: $saint-blue;
                .table__column-text {
                    color: white;
                    opacity: 1;
                }
            }
            &.active-grey {
                background-color: $ghost-white;
                //.table__column-text {
                //    color: white;
                //    opacity: 1;
                //}
            }
        }
    }
    &__header-text {
        font-size: .85em;
        font-weight: 800;
        color: $oxford-blue;
    }
    &__column-text {
        font-size: .85em;
        font-weight: 800;
        opacity: 0.7;
        color: $oxford-blue;
        &--light {
            font-weight: 200;
            color: $oxford-blue;
            font-size: $paragraph;
        }
    }
    &__column-secondary-text {
        font-size: 13px;
        font-weight: 800;
        opacity: 0.4;
        color: $midnight-blue;
        word-break: break-all;
    }
    td {
        border-top: 0px;
        vertical-align: middle;
    }
    thead th {
        border-bottom: 1px solid $lavender;
        border-top: 0px;
        font-weight: inherit;
    }
    &--no-shadow {
        box-shadow: none !important;
    }
    &--bordered {
        border: 1px solid $lavender;
        border-radius: $base-border-radius;
    }
}

.table-div {
    background-color: red;
}

@include media-breakpoint-down(md) {
    .table {
    
    }
}
