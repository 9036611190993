.primary {
    color: $brand-primary !important;
}

.green {
    color: $aquamarine !important;
}

.pointer-none {
    pointer-events: none !important;
}
.midnight-blue-400 {
    color: $midnight-blue-400;
}

.light-steel-blue {
    color: $light-steel-blue;
}

.border-radius {
    &__base {
        border-radius: $base-border-radius !important;
    }
}

.width {
    &__100 {
        width: 100%;

        &__important {
            width: 100% !important;
        }
    }
}

.height {
    &__100 {
        height: 100%;
    }
}

.opacity {
    &__600 {
        opacity: 0.6;
    }
}

.break-word {
    word-break: break-word;
}

.break-all {
    word-break: break-all;
}

.vertical-align-top {
    vertical-align: top !important;
}

.cursor-pointer {
    cursor: pointer;
}

.map-container {
    height: 600px
}
