.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    background-color: $space-cadet;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.05), 0 1px 18px 0 rgba(0,0,0,0.03), 0 6px 10px 0 rgba(0,0,0,0.04);
    display: flex;
    &__icon {
        font-size: 20px;
        width: 20px;
        margin-right: 20px;
    }
    .nav-link {
        font-weight: bold;
        color: white;
        opacity: 0.5;
        font-size: 13px;
        cursor: pointer;
        &:hover {
            color: white;
            opacity: 0.75;
        }
    }
    &__secondary-links {
        position: absolute;
        bottom: 0;
    }
}

.sidebar-small {
    max-width: 100px;
    min-width: 100px;
    display: flex;
    align-items: center;
    .nav-link {
        text-align: center;
    }
    .sidebar__icon {
        margin-right: 0;
    }
    .nav-item.active .nav-link, .nav-link.active{
        color: white;
        opacity: 1;
        margin-right: 0;
        .sidebar__icon {
            color: $brand-primary;
            background-color: $saint-blue;
            width: 55px;
            height: 55px;
            align-items: center;
            display: flex;
            justify-content: center;
            border-radius: $base-border-radius;
            i {
                margin-right: 0 !important;
            }
        }
        &:hover {
            opacity: 1;
        }
    }
}

.sidebar-subnav {
    background-color: $saint-blue;
    padding-left: 6em;
    position: fixed;
    height: calc(100vh - 48px);
    &__title {
        font-size: 20px;
        font-weight: 800;
        text-align: left;
    }
    .nav-link {
        margin-left: 1em;
        color: white;
        opacity: 0.7;
        font-weight: 600;
        font-size: 13px;
    }
    .nav-item {
        &.active {
            .nav-link {
                color: $brand-primary;
                background-color: #434499;
                border-top-left-radius: $base-border-radius;
                border-bottom-left-radius: $base-border-radius;
                opacity: 1;
                font-weight: 800;
            }

        }
    }
}

.nav-item.active .nav-link, .nav-link.active{
    color: white;
    opacity: 1;
    .sidebar__icon {
        color: $brand-primary;
    }
    &:hover {
        opacity: 1;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.permit-timeline {
    box-shadow: 0 6px 13px 0 rgba(52,48,144,0.10);
    border-radius: 10px;
    &__header {
        border-radius: 10px;
        padding: 1em;
        background-color: $oxford-blue;
    }
    &__header-text {
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
    }
    &__date-text {
        color: white;
        font-weight: 400;
        font-size: 11px;
    }
    &__body {
        padding: 1em;
        background-color: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    &__timeline {
        position: relative;
        padding-left: 0;
        &-action {
            color: $light-steel-blue;
            font-size: 13px;
        }
        &-timestamp {
            color: $oxford-blue-700;
            font-size: 11px;
        }
    }
    li{
        list-style: none;
        padding-bottom: 1.5rem;
        border-left: 2px solid $brand-primary;
        position: relative;
        padding-left: 20px;
        margin-left: 10px;
        &:first-child{
            &:before {
                content: '';
                width: 15px;
                height: 15px;
                background: $brand-primary;
                border: 4px solid $maximum-yellow;
                border-radius: 50%;
                position: absolute;
                left: -8px;
                top: 0px;
            }
        }
        &:last-child{
            border: 0px;
            padding-bottom: 0;
            &:before{
                left: -4px;
            }
        }
        &:before{
            content: '';
            width: 10px;
            height: 10px;
            background: white;
            border: 2px solid $brand-primary;
            border-radius: 50%;
            position: absolute;
            left: -6px;
            top: 0px;
        }
    }
}


@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

@include media-breakpoint-down(md) {
    .sidebar {
        top: 3em;
        z-index: 99;
        padding-top: 0;
    }
}

@include media-breakpoint-down(sm) {
    .sidebar {
        z-index: 999999999;
        padding-top: 0;
    }
}
