.upload {
    background: $ghost-white;
    border: 1px dashed $light-steel-blue;
    border-radius: $base-border-radius;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: .5em;
    width: 100%;
    &__delete-button {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    &__image-wrapper {
        position: relative;
        width: 100px;
        margin-right: 10px;
        > img {
            width: 100px;
            height: 100px;
            border-radius: $base-border-radius;
        }
    }
    &__limitbytes-text {
        color: $light-steel-blue;
        font-size: 13px;
    }
}

.react-datepicker__input-container {
    input {
        opacity: 1 !important;
        height: 38px;
        background: $ghost-white;
        border: 1px solid $light-steel-blue !important;
        border-radius: $base-border-radius !important;
        width: 100%;
        color: $oxford-blue !important;
        padding-left: 10px!important;
        &::-webkit-input-placeholder {
            color: $oxford-blue-500 !important;
        }
    }
}

.react-datepicker {
    font-family: 'Nunito Sans', sans-serif !important;
    background-color: #fff;
    border: none !important;
    border-radius: $base-border-radius !important;
    box-shadow: 0 6px 13px 0 rgba(52, 48, 144, 0.10);
    display: flex !important;
    z-index: 99999 !important;
    &-wrapper {
        display: flex !important;
    }
    &__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
        background-color: $brand-primary !important;
        color: white !important;
    }
    &__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 95px !important;
    }
    &__month-container {

    }
    &__time-container {
        border-left: 1px solid $lavender !important;
        margin-left: 15px !important;
    }

    &__header {
        background-color: transparent !important;
        border-bottom: none !important;
        border-top-left-radius: $base-border-radius !important;
        border-top-right-radius: $base-border-radius !important;
    }
    &__navigation--next {
        border-left-color: $brand-primary !important;
    }
    &__navigation--previous {
        border-right-color: $brand-primary !important;
    }
    &__day--selected {
        background-color: $brand-primary !important;
        color: white !important;
    }
    &__triangle {
        display: none;
    }
    &__current-month {
        color: $oxford-blue !important;
    }
    &__day-name {
        color: $light-steel-blue !important;
    }
    &__day {
        color: $oxford-blue;
    }
    // &__day--today {
        // color: white !important;
    // }
    &-popper {
        z-index: 5 !important;
    }
}

select {
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    cursor:pointer;
}

.dropdown {
    &__right {
        position: initial;
    }
    &__pullRight{
        right: 0 !important;
        left: auto !important;
    }

}

.dropdown-menu {
    width: max-content;

    &__block {
        width: 100%;
    }
}

.tag {
    display: inline-block;
    padding: .5em .6em;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    color: white;
    border-radius: $large-border-radius;
    background-color: $light-steel-blue;
    margin-right: 10px;
    &:hover {
        cursor: pointer;
        background-color: darken($light-steel-blue, 10);
        transition: background-color .2s ease-in-out;
    }
}

.rc-switch {
    border: 1px solid $light-steel-blue;
    background-color: $light-steel-blue;
    &-checked {
        border: 1px solid #fff;
        background-color: $brand-primary;
    }
}

.checkbox {
    &__table-row {
        top: 10px;
    }
}

/* Using css var aka custom properies, don't use them if you don't use tools such as postCss */
:root {
    --color-blue: #5897fc;
    --color-blue-grayed: #e0ecff;
}


/* First hide radios */

.input {
    &__radio {
        input[type="radio"] {
            display: none;
            &:checked ~ label {
                &:before {
                    background-color: white;
                    border: 6px solid $brand-primary;
                }
            }
        }
        label {
            display: block;
            cursor: pointer;
            margin-bottom: 0;
            &:before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid $light-steel-blue;
            }
        }
    }
    &__checkbox {
        display: block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
                background-color: $brand-primary;
                border: 1px solid $brand-primary;
                &:after {
                    display: block;
                }
            }
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid $light-steel-blue;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 7px;
                top: 4px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        &:hover input ~ .checkmark {
            border-color: darken($light-steel-blue, 10);
        }
        &--readonly {
            input {
                &:checked ~ .checkmark {
                    background-color: $light-steel-blue;
                    border: 1px solid $light-steel-blue;
                }
            }
        }
    }
}

.collapsible {
    cursor: pointer;
    &__container {
        height: 230px;
        overflow-y: auto;
        border: 1px solid $lavender;
        border-radius: $base-border-radius;
        padding-right: 10px;
    }
}