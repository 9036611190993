.step-item {
  background-color: white;
  border-radius: $base-border-radius;
  padding: 1.25em;
  &__title {
    color: $oxford-blue;
  }
  &__text {
    color: $oxford-blue-700;
    margin-top: 1em;
  }
  &--active {
    background-color: $oxford-blue;
    .step-item__title {
      color: white;
    }
  }
}

.fadeForMe-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fadeForMe-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.fadeForMe-exit {
  opacity: 1;
}
.fadeForMe-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.pagination {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  padding-left: 0;
  list-style: none;
  &__link {
    font-size: 13px;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.nav-add-section {
  position: relative;
  &__line {
    position: absolute;
    width: 100%;
    background-color: $light-steel-blue-500;
    height: 1px;
    bottom: 58px;
    z-index: 0;
  }
}
.e2e {
  .nav-controls {
    opacity: 1;
  }
}
.nav-controls {
  opacity: 1;
  transition: all 0.15s;
  &:hover {
    opacity: 1;
  }
}
