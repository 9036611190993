.icon {
    &__create-password {
        font-size: 26px;
    }
    &__signed {
        position: absolute;
        top: 0;
        right: -5px;
        color: $aquamarine;
        background-color: white;
        border-radius: 50%;
        //z-index: 999999;
    }
    &__rounded {
        color: white;
        padding: 4px 7px;
        border-radius: 4px;
        &--red {
            background-color: $red;
        }
        &--light-blue {
            background-color: $light-blue;
        }
        &--saint-blue {
            background-color: $saint-blue;
        }
    }
}
