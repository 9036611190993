.orange{
    background: #f17c57fa;
}

.step-number {
    background-color: $brand-primary;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px 1px $brand-primary;
    &__number {
        position: absolute;
        color: white;
        font-size: 12px;
        font-weight: bold;
    }
    &--active {
        animation: pulse-animation 2s infinite;
    }
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(247, 164, 0, 0.3);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(247, 164, 0, 0);
    }
}


@keyframes pulse-animation--primary {
    0% {
        box-shadow: 0 0 0 0px rgba(247, 164, 0, 0.3);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(247, 164, 0, 0);
    }
}


@keyframes pulse-animation--green {
    0% {
        box-shadow: 0 0 0 0px rgba(67, 220, 168, 0.3);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(67, 220, 168, 0);
    }
}

@keyframes pulse-animation--yellow {
    0% {
        box-shadow: 0 0 0 0px rgba(255,201,95, 0.3);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255,201,95, 0);
    }
}

@keyframes pulse-animation--red {
    0% {
        box-shadow: 0 0 0 0px rgba(255,85,85, 0.3);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255,85,85, 0);
    }
}


@keyframes pulse-animation--red-500 {
    0% {
        box-shadow: 0 0 0 0px rgba(255,85,85, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255,85,85, 0);
    }
}

@keyframes pulse-animation--primary-500 {
    0% {
        box-shadow: 0 0 0 0px rgba(247, 164, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(247, 164, 0, 0);
    }
}

@keyframes pulse-animation--saint-blue {
    0% {
        box-shadow: 0 0 0 0px rgba(41, 42, 115, 0.3);
    }
    100% {
        box-shadow: 0 0 0 0px rgba(41, 42, 115, 0);
    }
}

@keyframes pulse-animation--saint-blue-500 {
    0% {
        box-shadow: 0 0 0 0px rgba(41, 42, 115, 0.2);
    }
    100% {
        box-shadow: 0 0 0 0px rgba(41, 42, 115, 0);
    }
}

@keyframes pulse-animation--light-blue {
    0% {
        box-shadow: 0 0 0 0px rgba(58, 158, 253, 0.3);
    }
    100% {
        box-shadow: 0 0 0 0px rgba(58, 158, 253, 0);
    }
}


