@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

@font-face {
  font-family: 'Proxima Nova';
  src: url('/static/fonts/fa/');
  font-display:swap;
}
// Adding fonts
//@font-face {
//  font-family: 'Proxima Nova';
//  src: url('/static/fonts/Proxima-Nova-Regular.woff');
//  font-display:swap;
//}
//@font-face {
//  font-family: 'Proxima Nova';
//  src: url('/static/fonts/Proxima-Nova-Semibold.woff');
//  font-weight:bold;
//  font-display:swap;
//}
//@font-face {
//  font-family: 'Proxima Nova Bold';
//  src: url('/static/fonts/Proxima-Nova-Semibold.woff');
//  font-display:swap;
//}

// Colours
$brand-primary: #F7A400;
$brand-primary-500: rgba(247,164,0,0.5);
$brand-primary-100: rgba(247,164,0,0.1);
$ghost-white: #F4F6FC;
$oxford-blue: #0B102E;
$midnight-blue: #070B68;
$midnight-blue-400: rgba(7,11,104,0.4);
$saint-blue: #313273;
$saint-blue-500: rgba(41,42,115,0.5);
$red: #E15555;
$red-500: rgba(225,85,85,0.5);
$aquamarine: #43DCA8;
$oxford-blue-500: rgba(11,16,46, 0.5);
$oxford-blue-700: rgba(11,16,46, 0.7);
$light-blue: #3A9EFD;
$light-blue-200: rgba(58,158,253,0.2);
$light-steel-blue: #A4B5D2;
$light-steel-blue-500: rgba(164,181,210, 0.5);
$light-steel-blue-200: rgba(164,181,210, 0.2);
$light-steel-blue-100: rgba(164,181,210, 0.1);
$maximum-yellow: #FFC95F;
$space-cadet: #1A1B4B;
$lavender: #E7E7FC;
$lavender-300: rgba(231,231,252,0.3);



//1A1B4B

$paragraph: 15px;

$h3: 1.2em;
$h4: 1em;
$h5: .8em;
$h6: 0.938rem;





$brand-secondary: #D8315B;
$link-color: #05499b;


// Border Radius

$base-border-radius: 6px;
$large-border-radius: 12px;


//Input component variables
$alert-danger-border: #c70000;
$input-line-height: 25px;
$input-font-size: inherit;
$input-font-weight: 200;
$input-border-color: rgb(224, 224, 224);
$input-placeholder-color: rgb(224, 224, 224);
$input-border-highlight-color: rgb(0, 188, 212);

//Panel component variables
$panel-heading-height: 50px;
$panel-bg: #fff;
$panel-heading-bg: #f7f7f7;
$panel-heading-color: #333;
$panel-heading-border: 1px solid #f1f1f1;
$panel-icon-color: #36a39c;
$panel-icon-border-color: #ddd;
$panel-icon-background-color: #fff;
$panel-alt-heading-bg: #36a39c;
$panel-alt-heading-color: #fff;

//Tab component styles
$tab-background: transparent;
$tab-background-hover: transparent;
$tab-background-active: transparent;
$tab-active-background: transparent;
$tab-inactive-color: lighten(black, 20);
$tab-active-color: black;
$tab-line-color: $brand-primary;

// Status TemplateVersionTag
$tag-info-bg: white;
$tag-info-border-color: gray;
$tag-info-color: black;

$tag-active-bg: rgba(67, 220, 168, 0.3);
$tag-active-color: #3abe91;

$tag-draft-bg: #A4B5D2;
$tag-draft-color: white;

//Scaffolding
$breakpoints: (
    mobile-small: 320px,
    mobile: 375px,
    handheld-device: 576px,
    tablet: 768px,
    tablet-large: 992px,
    desktop: 1200px
)
