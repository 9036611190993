.logo {
    width: 240px;
    height: 37px;
}

.profile-list-item {
    &__image {
        display: inline-block;
        width: 36px !important;
        height: 36px !important;
    }
}

.organisation {
    &__image {
        width: 40px;
        height: 40px;
        border-radius: $base-border-radius;
    }
}

.organisation-image {
    &--small {
        width: 20px;
        height: 20px;
        border-radius: $base-border-radius;
    }
}


.signed-by-avatar {
    &__container {
        flex-direction: row-reverse !important;
        justify-content: flex-end;
        .signed-by-avatar {
            &:nth-child(2n) {
                //background-color: $oxford-blue;
            }
        }
    }
    position: relative;
}
