.question-builder

.question-card {
    ul {
        li {
            color: #22354a;
            opacity: 0.85;
            font-size: $paragraph;
        }
    }
    &__bold-text {
        color: $oxford-blue;
    }
}

.question-proof {
    width: 100px;
}

.question-group {
    position: relative;
    &__label {
        background: rgba(255,255,255,0.25);
        border-radius: $base-border-radius;
        text-transform: uppercase;
        font-size: 11px;
        color: $oxford-blue-500;
        width: 130px;
        padding: 2px 11px;
        position: absolute;
        font-weight: bold;
        top: -9px;
    }
}

.template-sidebar {
    /* max-width: 85px; */
    background-color: #E7E7FC;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100vh;
    position: fixed;
    left: 16.5%;
    width: 55px;
    margin-left: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 43px;
    z-index: 1020;
    top: 0;

    &__item {
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 16px;
        padding-top: 6px;
        padding-bottom: 6px;
        border-radius: 6px;
        width: 34px;
    }

    &__active {
        background-color: #292A73;
    }

    &__active i {
        color: #f7a400 !important;
    }

    &__projects {
        left: 25%;
    }
}

.template-sidebar i {
    color: #292A73;
}

@include media-breakpoint-down(md) {
  .template-sidebar {
      left: 25%;

      &__projects {
        left: 30%;
      }
  }
}

@include media-breakpoint-down(sm) {
  .template-sidebar {
    left: 0;
  }
}


