.navbar {
    display: flex;
    justify-content: flex-start;
    &__logo {
        width: 160px;
        height: 25px;
    }
    .profile {
        &__container {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
        }
        &__name {
            font-size: 15px;
            font-weight: bold;
            display: block;
            color: $oxford-blue;
            cursor: pointer;
            &:hover {
                color: $brand-primary;
            }
        }
        &__title {
            font-size: 13px;
            font-weight: bold;
            display: block;
            color: $light-steel-blue;
        }
        &__notification {
            color: $lavender;
        }
        &__image {
            cursor: pointer;
        }
    }
}

.navbar-organisation-controls {
    z-index: 99999999;
    width: 100%;
    background-color: $oxford-blue-700;
    position: fixed;
    bottom: 0;

}

.navbar-overlay-controls {
    z-index: 99999999;
    width: 73%;
    background-color: $oxford-blue-700;
    position: fixed;
    top: 0;
}

.navbar-container {
    width: 100%;
    background-color: $ghost-white;
    height: 56px;
    position: relative;
}

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: $space-cadet;
    text-align: center;
}

.navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
    border: none;
}

.navbar-small {
    background-color: #313273;
    height: 57px;
    display: flex;
    &__logo {
        background-color: $space-cadet;
        width: 100px;
        display: flex;
        justify-content: center;
    }
}

@include media-breakpoint-down(sm) {
    .navbar-small {
        background-color: $space-cadet;
    }
    .navbar {
        .profile {
            &__container {
                justify-content: flex-end;
            }
            &__name {
              display: none;
            }
            &__title {
                display: none;
            }
            &__notification {
                padding-left: 10px;
            }
        }
    }
}
