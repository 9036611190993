@import "../variables";

textarea {
	width: 100%;
}

.focused {
	input, textarea {
		border: 1px solid $brand-primary !important;
		outline: none;
	}
}

.invalid {
	input, textarea {
		border-color: $red !important;
	}
}
select.invalid {
	border-color: $red !important;
}
.toggle-control {
	background-color: $brand-primary;
}

.input-container {
	font-size: $input-font-size;
	line-height: $input-line-height;
	display: inline-block;
	position: relative;
	width: 100%;
	align-items: center;
	&__white {
		input, textarea {
			background-color: white !important;
		}
	}
	&__slim {
		input {
			background-color: white !important;
		}
	}
	&__required {
		label {
			&:before {
				content: '*';
				color: $red;
				padding-right: 10px;
			}
		}
	}
	&__disabled {
		input {
			opacity: 0.7;
			cursor: not-allowed;
		}
	}

	label {
		color: $oxford-blue;
		display: block;
		margin-bottom: 0;
	}

	input {
		height: 38px;
		background: #F4F6FC;
		border: 1px solid $light-steel-blue;
		border-radius: $base-border-radius;
		width: 100%;
		padding-left: 10px;
		color: $oxford-blue;
		padding-right: 35px;
		&::-webkit-input-placeholder {
			color: $oxford-blue-500;
		}
	}

	&__number {
		input {
			padding-right: 10px;
		}
	}

	.input--white {
		input {
			background: white !important;
		}
	}
	&__transparent {
		input {
			background: transparent !important;
			border: 1px solid transparent;
			border-radius: $base-border-radius;
			width: 100%;
			padding-left: 10px;
			color: $oxford-blue;
			padding-right: 35px;
			&::-webkit-input-placeholder {
				color: $oxford-blue-500;
			}
		}
		&.focused {
			background-color: white;
			border-radius: $base-border-radius;
		}
	}
	&__large {
		input {
			height: 48px;
			font-size: 24px;
			font-weight: 900;
		}
		.icon {
			bottom: 16px !important;
		}
	}
	&__medium {
		input {
			height: 40px;
			font-size: 20px;
			font-weight: 900;
		}
		.icon {
			bottom: 13px !important;
		}
	}
	&__select-small {
		label {
			//font-weight: bold;
			font-size: 15px;
		}
		.select select {
			background: white;
		}
	}
	&__small {
		input {
			height: 28px !important;
			font-size: 13px;
		}

	}
}

textarea {
	width: 100%;
	padding: .5em;
	color: $oxford-blue;
	background-color: $ghost-white;
	border-radius: $base-border-radius;
	border-color: $light-steel-blue;
}

.textarea {
	&--white {
		background-color: white;
	}
	&--text-small {
		font-size: 0.75rem;
		line-height: 18px;
	}
}

/* .squaredThree checkbox styles */
.squaredThree {
	position: relative;
	float:left;
}

.squaredThree label {
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	background: $brand-primary;
	border-radius: 4px;
}

.squaredThree label:after {
	content: '';
	width: 9px;
	height: 5px;
	position: absolute;
	top: 6px;
	left: 5px;
	border: 3px solid #fcfff4;
	border-top: none;
	border-right: none;
	background: transparent;
	opacity: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.squaredThree label:hover::after {
	opacity: 0.3;
}

.squaredThree input[type=checkbox] {
	visibility: hidden;
}

.squaredThree input[type=checkbox]:checked + label:after {
	opacity: 1;
}

/* end .squaredThree */

.label-text {
	position: relative;
	left: 10px;
}



@include media-breakpoint-up(lg) {
	.input-container {
		&__slim {
			width: 50%;
		}
	}
}
